// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-templates-blog-index-jsx": () => import("./../../../src/templates/blog-index.jsx" /* webpackChunkName: "component---src-templates-blog-index-jsx" */),
  "component---src-templates-markdown-page-jsx": () => import("./../../../src/templates/markdown-page.jsx" /* webpackChunkName: "component---src-templates-markdown-page-jsx" */),
  "component---src-templates-markdown-post-jsx": () => import("./../../../src/templates/markdown-post.jsx" /* webpackChunkName: "component---src-templates-markdown-post-jsx" */)
}

